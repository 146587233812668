export default {
  common: {
    siteName: 'Nidomio',
    welcome: 'Bienvenue',
    hello: 'Bonjour',
    goodbye: 'Au revoir',
    notSpecified: 'Non renseigné',
    accountCreate: 'Créer mon compte @:common.siteName',
    cancel: 'Annuler',
    save: 'Enregistrer',
    return: 'Retour',
    requiredFields: '* Champs obligatoires',
    createScenario: 'Créer un scénario',
    createNewScenario: 'Créer un nouveau scénario',
    actionLabel: 'Action',
    actionsLabel: 'Actions',
    relaunched: 'Relancer',
    error: 'Erreur',
    download: 'Télécharger',
    delete: 'Supprimer',
    add: 'Ajouter',
    update: 'Modifier',
    operationalArea: "Zone d'intervention",
    associatedCompany: 'Entreprise associée',
    agencies: 'Agences',
    employees: 'Salariés',
    users: 'Utilisateurs',
    projects: 'Projets',
    affiliatedProjects: 'Projets affiliés',
    partner: 'Partenaire',
    billing: 'Facturation',
    search: 'Rechercher...',
    myDashboard: 'Mon tableau de bord',
    DashboardWelcomePhrase: 'Bonjour, voici ce qu’il se passe aujourd’hui.',
    thisWeek: 'cette semaine',
    noProject: 'Aucun projet',
    seeThe: 'Voir les',
    yes: 'Oui',
    no: 'Non',
    toasts: {
      error: {
        title: 'Une erreur est survenue'
      },
      client: {
        deleted: {
          title: 'Client supprimé !',
          content: "Le client {name} vient d'être supprimé."
        }
      },
      artisan: {
        created: {
          title: 'Arisan créé !',
          content: "L'artisan {name} vient d'être créé."
        },
        updated: {
          title: 'Arisan modifié !',
          content: "L'artisan {name} vient d'être modifié."
        },
        deleted: {
          title: 'Artisan supprimé !',
          content: "L'artisan {name} vient d'être supprimé."
        }
      },
      artisanCompany: {
        created: {
          title: 'Entreprise professionnelle créé !',
          content: "L'entreprise {name} vient d'être créée."
        },
        updated: {
          title: 'Entreprise professionnelle modifié !',
          content: "L'entreprise {name} vient d'être modifiée."
        },
        deleted: {
          title: 'Entreprise professionnelle supprimée !',
          content: "L'entreprise {name} à été supprimée."
        }
      },
      artisanAgency: {
        created: {
          title: 'Agence professionnelle créé !',
          content: "L'agence professionnelle {name} vient d'être créée."
        },
        updated: {
          title: 'Agence professionnelle modifié !',
          content: "L'agence professionnelle {name} vient d'être modifiée."
        },
        deleted: {
          title: 'Agence artisan supprimée !',
          content: "L'agence {name} à été supprimée."
        }
      },
      accompanist: {
        created: {
          title: 'Accompagnateur créé !',
          content: "L'accompagnateur {firstname} {lastname} vient d'être créé."
        },
        updated: {
          title: 'Accompagnateur modifié !',
          content: "L'accompagnateur {firstname} {lastname} vient d'être modifié."
        }
      },
      accompanistCompany: {
        created: {
          title: 'Entreprise accompagnateur créé !',
          content: "L'entreprise {name} vient d'être créée."
        },
        updated: {
          title: 'Entreprise accompagnateur modifié !',
          content: "L'entreprise {name} vient d'être modifiée."
        },
        deleted: {
          title: 'Entreprise accompagnateur supprimée',
          content: "L'entreprise {name} vient d'être supprimée."
        }
      },
      accompanistAgency: {
        created: {
          title: 'Agence accompagnatrice créé !',
          content: "L'agence {name} vient d'être créée."
        },
        updated: {
          title: 'Agence accompagnatrice modifié !',
          content: "L'agence {name} vient d'être modifiée."
        },
        deleted: {
          title: 'Agence accompagnatrice supprimée !',
          content: "L'agence {name} vient d'être supprimée."
        }
      },
      project: {
        updated: {
          title: 'Projet modifié !',
          content: "Le projet vient d'être modifié."
        },
        deleted: {
          title: 'Projet supprimé !',
          content: "Le projet {name} vient d'être supprimé."
        }
      },

      diagnosticianCompany: {
        created: {
          title: 'Entreprise diagnostiqueuse créée !',
          content: "L'entreprise {name} vient d'être créée."
        },
        updated: {
          title: 'Entreprise diagnostiqueuse modifiée !',
          content: "L'entreprise {name} vient d'être modifiée."
        },
        deleted: {
          title: 'Entreprise diagnostiqueuse supprimée',
          content: "L'entreprise {name} vient d'être supprimée."
        }
      },
      diagnosticianAgency: {
        created: {
          title: 'Agence diagnostiqueuse créée !',
          content: "L'agence {name} vient d'être créée."
        },
        updated: {
          title: 'Agence diagnostiqueuse modifiée !',
          content: "L'agence {name} vient d'être modifiée."
        },
        deleted: {
          title: 'Agence diagnostiqueuse supprimée !',
          content: "L'agence {name} vient d'être supprimée."
        }
      },
      urlRegistration: {
        title: "Url d'inscription copié",
        content: "L'url {url} a été copié dans le presse-papiers. Utiliser Ctrl+v pour coller."
      }
    },
    taxProfil: {
      BLUE: 'Profil fiscal bleu',
      YELLOW: 'Profil fiscal jaune',
      PURPLE: 'Profil fiscal violet',
      PINK: 'Profil fiscal rose'
    },
    header: {
      labelLogo: "Aller à l'accueil du site @:common.siteName",
      client: {
        labelLogo: 'Aller sur le tableau de bord'
      },
      menuLabel: 'Menu'
    },
    sideBarMenu: {
      header: {
        closeLabel: 'Fermer'
      }
    },
    actions: {
      add: 'Ajouter',
      update: 'Modifier',
      reset: 'Réinitialiser'
    },
    navigation: {
      menu: {
        myDashboard: 'Mon tableau de bord',
        clients: 'Clients',
        projects: 'Projets',
        documents: 'Documents',
        renovationTeam: "Equipe Rénov'",
        accompanists: 'Accompagnateurs',
        companies: 'Entreprises',
        agencies: 'Agences',
        naturalPersonsAccompanist: 'Salariés',
        professionals: 'Professionnels',
        naturalPersonsProfessional: 'Salariés',
        diagnosticians: 'Diagnostiqueurs',
        billing: 'Facturation',
        partnerMap: 'Carte des partenaires',
        myPersonalData: 'Mes informations personnelles',
        technic: 'Technique',
        statistics: 'Statistiques',
        featureFlipping: 'Gestion feature-flipping',
        recovery: 'Redressement des données',
        activityKpi: 'KPI Métiers',
        activityKpiMarketing: 'KPI Marketing',
        logout: 'Déconnexion'
      }
    },
    errors: {
      tokenInvalid:
        'Le lien que vous venez d’utiliser a expiré. Merci de compléter à nouveau le formulaire.',
      incorrectFilling: 'Merci de remplir correctement le numéro de téléphone.'
    },
    financo: {
      dataProcessing: {
        description:
          'Financo est responsable du traitement de vos données. Les informations personnelles' +
          ' collectées sur ce formulaire seront utilisées par Financo afin de permettre la création de' +
          ' votre compte client, la gestion de votre compte client, et si vous y avez consenti à des' +
          ' fins de prospection commerciale par voie numérique par Financo. En savoir plus sur' +
          ' {linkDataRights}.',
        nameLinkDataRights: 'la gestion de vos données et vos droits'
      },
      description:
        'Financo, Société anonyme à directoire et conseil de surveillance au capital de' +
        ' 58.000.000,00 euros- siège social 335 rue Antoine de Saint-Exupéry 29490 GUIPAVAS, RCS' +
        ' BREST 338 138 795.'
    },
    form: {
      offerConsent:
        "J'accepte que mes données personnelles soient utilisées pour être recontacté(e) à des fins de prospection commerciale de @:{'common.siteName'}, marque de Financo, par voie numérique.",
      requiredField: 'Ce champ est requis',
      inputEmailPlaceholder: 'Adresse email',
      submitButtonLabel: 'Valider mon adresse email',
      error: {
        validateEmail:
          "Une erreur s'est glissée dans votre adresse email. Merci de la vérifier pour accéder à votre espace personnel.",
        invalidEmail: 'Merci de saisir une adresse email valide.',
        invalidSiren: 'Le numéro Siren doit contenir 9 chiffres.',
        invalidSiret: 'Le numéro Siret doit contenir 14 chiffres.',
        codeAffiliated: 'Ce code affilié existe déjà'
      },
      fields: {
        password: 'Mot de passe',
        phone: 'Téléphone fixe',
        mobilePhone: 'Téléphone Portable',
        identity: 'Identité',
        email: 'Adresse email',
        firstname: 'Prénom',
        lastname: 'Nom',
        businessName: 'Nom commercial',
        nameLegalPerson: 'Raison sociale',
        siren: 'Siren',
        siret: 'Siret',
        rcsNumber: 'Numéro RCS',
        formSociety: 'Forme société',
        webSite: 'Site Internet',
        workTypes: 'Types de travaux',
        codeAffiliated: 'Code Affilié'
      },
      placeholders: {
        password: 'Votre nouveau mot de passe',
        search: 'Rechercher dans les projets...'
      },
      errors: {
        notAllowed:
          'Les caractères spéciaux ne sont pas autorisés, merci de modifier votre saisie.',
        mail: "Le format de l'adresse email est invalide, merci de modifier votre saisie.",
        zipCityMandatory: 'Veuillez sélectionner une ville ou un code postal.',
        client: {
          address:
            "La saisie de votre adresse est essentielle pour l'enregistrement de votre projet, merci de modifier votre saisie.",
          zipCity:
            "La saisie de votre code postal ou de votre ville est essentielle pour l'enregistrement de votre projet, merci de modifier votre saisie."
        },
        accompanist: {
          zip: "La saisie de votre code postal est essentielle pour l'enregistrement du projet, merci de modifier votre saisie.",
          city: 'La saisie de votre ville est essentielle pour l’enregistrement du projet, merci de modifier votre saisie.'
        }
      }
    },
    project: {
      libelle: 'Projets',
      myDashboard: 'Tableau de bord',
      manage:
        'Gérez votre projet de rénovation sur la plateforme et facilitez toutes vos démarches.',
      createProject: 'Créer un nouveau projet',
      projectItems: {
        title: 'Accéder à votre projet : {name}'
      }
    },
    addressForm: {
      address: 'Adresse',
      addressHeadOffice: 'Adresse du siège social',
      additionalAddress: "Complément d'adresse",
      zipCity: 'Ville ou code postal',
      zipCityHeadOffice: 'Ville ou code postal du siège social',
      postalBox: 'Boîte postale',
      postalBoxHeadOffice: 'Boîte postale du siège social',
      cedex: 'CEDEX',
      cedexHeadOffice: 'CEDEX du siège social',
      zip: 'Code postal',
      city: 'Ville',
      saveAddressAsMailingAddress:
        'Enregistrer cette adresse comme adresse postale de correspondance',
      other: 'Utiliser une autre adresse'
    },
    button: {
      return: 'Annuler',
      register: 'Enregistrer'
    },
    tooltips: {
      password: {
        display: 'Afficher le mot de passe',
        hide: 'Masquer le mot de passe'
      },
      dialog: {
        close: 'Fermer'
      },
      accordion: {
        show: 'Développé',
        hide: 'Masqué'
      },
      inputNumber: {
        increase: 'Augmenter',
        decrease: 'Diminuer'
      }
    },
    multiSelect: {
      emptyMessage: 'Le champs est vide'
    },
    messages: {
      updateData: 'Votre modification est enregistrée',
      postalAddress: 'Merci de renseigner votre adresse postale de correspondance.'
    },
    event: {
      ASSOCIATION_ACCOMPANIST_COMPANY_TO_PROJECT:
        '{accompanistCompany} est bien associé à votre projet {projectTitle}',
      ACCOMPANIST_COMPANY_CREATE_SCENARIO:
        'Un nouveau scénario a été créé pour le projet {projectTitle} par {accompanistCompany}'
    },
    naturalPersonEdit: {
      titleCreate: "Creation d'une personne",
      defineAddress: 'Définir une adresse',
      updateAddress: "Modifier l'adresse",
      cancel: 'Annuler',
      save: 'Enregistrer'
    },
    legalPersonEdit: {
      titleEdit: 'Détails',
      titleCreate: "Creation d'une personne légal",
      defineAddress: 'Définir une adresse',
      updateAddress: "Modifier l'adresse",
      defineHeadOfficeAddress: 'Définir les informations de mentions légales',
      updateHeadOfficeAddress: 'Modifier les informations de mentions légales',
      cancel: 'Annuler',
      save: 'Enregistrer',
      duplicateIntoAgency: 'Dupliquer cette entreprise en agence'
    },
    company: {
      associated: 'Entreprise associée',
      placeholder: 'Selectionnez une entreprise'
    },
    searchPlaceholder: 'Valeur à rechercher',
    table: {
      columns: {
        email: 'Adresse mail',
        name: 'Nom commercial',
        city: 'Ville'
      }
    },
    recovery: {
      title: 'Redressement des données',
      label: 'Redresser',
      table: {
        label: 'Libelle'
      },
      eligibility: 'Redressement création eligibilité',
      professionalAccess: 'Redressement accès aux professionels en étape 1',
      inaccurateAddresses: 'Redressement des adresses sans coordonnées',
      constructionPeriod: 'Redressement des périodes de construction',
      changeConsentDataAccompanist:
        'Redressement des consentement accompagnateur entreprise vers agence',
      clientTypeStatus: 'Redressement du status des clients',
      toast: {
        summary: 'Redressement effectué'
      }
    },
    popin: {
      delete: {
        warning:
          'La suppression est définitive. Elle pourra impacter le compte et la situation des utilisateurs reliés à cet élément.',
        cantDeleteCompany:
          'Vous ne pouvez pas supprimer cette entreprise car elle est liée à une ou plusieurs agence(s).',
        concernedUsers: 'Utilisateurs impactés :',
        concernedAgencies: 'Agences concernées :',
        noProjectLinked: "Aucun projet n'est lié à cette donnée.",
        accept: 'Oui, supprimer définitivement',
        cancel: 'Non, annuler'
      }
    },
    ocr: {
      download: 'Téléchargement des données',
      extract: 'Extraction des données',
      synchronize: 'Synchronisation des données',
      refresh: 'Mettre à jour les données',
      endDate: 'Date de fin :',
      endDateExtract: 'Date de fin extraite :'
    }
  }
}
