export default {
  document: {
    documents: {
      title: 'Liste des documents',
      table: {
        title: 'Liste des documents',
        columns: {
          fileName: 'Nom du document',
          extension: 'Extension',
          type: 'Type de document',
          creationDate: 'Date de création'
        },
        detail: 'Détail du client associé',
        download: 'Télécharger'
      },
      tagChoice: {
        placeholder: 'Selectionnez un type',
        choiceLibelle: 'Type de document'
      },
      tags: {
        TAG_RECEIPT: 'Facture',
        TAG_QUOTATION: 'Devis',
        TAG_AUDIT: 'Audit',
        TAG_DPE: 'DPE',
        TAG_EVENT: "Avis d'imposition",
        TAG_REPORT: 'Audit',
        TAG_OTHER: 'Autre'
      }
    }
  }
}
