import { defineStore } from 'pinia'
import type { ErrorAPI } from '@/common/models/errorAPI'

export const useTrackDataAPIStore = defineStore('TrackDataAPI', {
  state: () => {
    return {
      errorsAPI: new Map<string, ErrorAPI>(),
      loading: new Array<string>()
    }
  },
  getters: {
    getErrorByTrackId: (state) => {
      return (trackId: string) => state.errorsAPI.get(trackId)
    },
    hasLoading: (state) => {
      return () => state.loading.length > 0
    },
    hasLoadingByTrackId: (state) => {
      return (trackId: string) => state.loading.find((id) => id == trackId)
    },
    hasLoadingByTrackIds:
      (state) =>
      (...trackIds: string[]) => {
        return trackIds.some((trackId) => state.loading.includes(trackId))
      }
  },
  actions: {
    addErrors: function (trackId: string, error: ErrorAPI) {
      this.errorsAPI.set(trackId, error)
    },
    removeErrors: function (trackId: string) {
      this.errorsAPI.delete(trackId)
    },
    addLoading: function (trackId: string) {
      this.loading.push(trackId)
    },
    deleteLoading: function (trackId: string) {
      const index = this.loading.indexOf(trackId)
      if (index > -1) {
        this.loading.splice(index, 1)
      }
    }
  }
})
