import { createI18n } from 'vue-i18n'
import accompanist_fr from '@/accompanist/i18n/fr'
import account_fr from '@/account/i18n/fr'
import artisan_fr from '@/artisan/i18n/fr'
import budget_fr from '@/budget/i18n/fr'
import client_fr from '@/client/i18n/fr'
import common_fr from '@/common/i18n/fr'
import project_fr from '@/project/i18n/fr'
import document_fr from '@/document/i18n/fr'
import scenario_fr from '@/project/i18n/scenario/fr'
import simulation_fr from '@/simulation/i18n/fr'
import user_fr from '@/user/i18n/fr'
import feature_flipping_fr from '@/feature-flipping/i18n/fr'
import activity_kpi_fr from '@/activity-kpi/i18n/fr'
import diagnostician_fr from '@/diagnostician/i18n/fr'
import person_fr from '@/person/i18n/fr'
import billing_fr from '@/billing/i18n/fr'

const i18n = createI18n({
  locale: 'fr',
  messages: {
    fr: {
      ...accompanist_fr,
      ...account_fr,
      ...artisan_fr,
      ...budget_fr,
      ...client_fr,
      ...common_fr,
      ...project_fr,
      ...document_fr,
      ...scenario_fr,
      ...simulation_fr,
      ...user_fr,
      ...feature_flipping_fr,
      ...activity_kpi_fr,
      ...diagnostician_fr,
      ...person_fr,
      ...billing_fr
    }
  },
  datetimeFormats: {
    fr: {
      short: {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric'
      },
      shortDateTime: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      }
    }
  },
  legacy: false
})

export { i18n }
