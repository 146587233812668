export default {
  client: {
    title: 'Clients',
    download: 'Télécharger la liste des clients',
    status: {
      PROSPECT: 'Prospect',
      QUALIFIED_PROSPECT: 'Prospect qualifié',
      CLIENT: 'Client',
      ACTIVE_PROSPECT: 'Prospect actif',
      ACTIVE_CLIENT: 'Client actif'
    },
    period: {
      label: 'Sélectionner une période',
      creation: {
        title: 'Période des clients créés :'
      },
      lastConnection: {
        title: 'Période des dernières connexions :'
      }
    },
    tag: {
      createdProject: 'Projet créé :',
      excludedEmails: 'Inclure les emails exclus :',
      clientType: 'Type de client :'
    },
    detail: {
      title: 'Fiche client',
      personalInfo: 'Informations personnelles',
      contactInfo: 'Coordonnées',
      addressInfo: 'Adresse de correspondance',
      impersonate: 'Débrancher vers le Front'
    },
    table: {
      title: 'Liste des clients',
      columns: {
        lastname: 'Nom',
        firstname: 'Prénom',
        phoneNumber: 'Numéro de téléphone',
        mobilePhoneNumber: 'Téléphone mobile',
        projectCreated: 'Projet créé',
        clientType: 'Type',
        dateCreated: 'Date de création',
        lastConnection: 'Dernière connexion'
      },
      detail: 'Détail du client',
      projectSummary: 'Résumé du projet'
    },
    projects: {
      title: 'Liste des projets',
      backWording: {
        detail: 'Retourner à la liste des clients'
      },
      noProject: 'Aucun projet pour ce client',
      table: {
        title: 'Liste des projets',
        columns: {
          projectNumber: 'Numéro',
          title: 'Nom du projet',
          city: 'Ville',
          status: 'Etat du projet',
          typeProject: 'Type de projet',
          housingType: 'Type de logement',
          accompanist: 'Accompagnateur',
          summary: 'Détail du projet'
        }
      },
      delete: {
        title: 'Supprimer définitivement un projet',
        questionConfirm: 'Êtes-vous sûr de vouloir supprimer le projet {title} définitivement ?',
        descriptionAction: 'Vous ne pourrez plus accéder à ce projet.'
      }
    },
    projectSummary: {
      prefixTitle: 'Projet : ',
      backWording: {
        detail: 'Retourner à la liste des projets'
      }
    },
    delete: {
      title: 'Supprimer définitivement un client'
    }
  },
  documents: {
    files: 'Documents',
    noDocuments: 'Aucun document trouvé',
    search: {
      placeholder: 'Rechercher dans les documents ...'
    },
    dateCreation: 'Ajouté le {date}',
    tags: {
      RECEIPT: 'Facture',
      QUOTATION: 'Devis',
      AUDIT: 'Audit',
      DPE: 'DPE',
      EVENT: "Avis d'imposition",
      REPORT: 'Audit',
      OTHER: 'Autre'
    }
  }
}
